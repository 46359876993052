/* eslint-disable @typescript-eslint/ban-ts-ignore */
<template>
  <ion-page>
    <ion-header >
      <header-toolbar >
        <ion-title :style="getTextStyle">
          {{ appBranding.contactInfoHeader }}
        </ion-title>
      </header-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid
        class="profile-header"
        :style="``"
      >
        <ion-row>
          <ion-col>
            <div class="img-rounded">
              <ion-avatar>
                <img :src="coachPhoto" />
              </ion-avatar>
            </div>
            <p class="profile-header-info" :style="getContentStyle">
              {{ appBranding.contactInfoName }}
            </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div
              :style="getContentStyle"
              v-html="brHtml"
              class="content"
            ></div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid style="font-size: 13px" class="profile-links">
        <ion-row>
          <ion-col>
            <ion-list lines="none">

              <template
                :key="index"
                v-for="(item, index) in currentCoach.coachLinks"
              >
                <ion-item detail="false" :href="item.url">
                  <ion-label :style="getContentStyle">
                    <i :class="`${getIconsForLinkTypes(item)} `"></i>
                    {{ item.label }}
                  </ion-label>
                </ion-item>
              </template>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonCol,
  IonTitle,
  IonHeader,
  IonRow,
  IonList,
  IonLabel,
  IonItem,
  IonAvatar,
} from "@ionic/vue";
import {
  globeOutline,
  logoFacebook,
  mailOutline,
  openOutline,
} from "ionicons/icons";
import HeaderToolbar from "../components/HeaderToolBar.vue";
export default {
  name: "CoachTab",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonCol,
    IonTitle,
    IonHeader,
    IonRow,
    HeaderToolbar,
    IonList,
    IonLabel,
    // IonListHeader,
    IonItem,
    IonAvatar,
  },
  methods: {
    ...mapActions({
      requestBranding: "getSubscribedCoach",
    }),
  },
  computed: {
    ...mapGetters({
      appBranding: "getAppBranding",
      currentCoach: "getCoach",
      activeProgram: "getActiveProgram",
    }),
    getContentStyle() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return `color: ${this.appBranding.contentColor}; font-size: ${this.appBranding.fontSize}; font-family: ${this.appBranding.fontFamily};`;
    },
    getTextStyle() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return `color: ${this.appBranding.menuTextColor};  font-family: ${this.appBranding.fontFamily};`;
    },
    brHtml(){
      const val = this.appBranding ?  this.appBranding.contactInfoIntroduction.replace(/(\r\n|\r|\n)/g,"<br>") : ''
      return val
    },

    coachPhoto() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return this.appBranding && this.appBranding.contactInfoPhoto
        ? this.appBranding.contactInfoPhoto
        : "assets/no-profile-picture-icon-27.jpg";
    },
  },
  created() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.requestBranding();
  },
  setup() {
    const faIconsForLinkTypes = [
      {
        linkName: "Web",
        iconName: "globe",
        faStyle: "fas",
      },
      {
        linkName: "Facebook",
        iconName: "facebook",
        faStyle: "fa-brands",
      },
      {
        linkName: "InstaGram",
        iconName: "instagram",
        faStyle: "fa-brands",
      },
      {
        linkName: "Twitter",
        iconName: "twitter",
        faStyle: "fa-brands",
      },
      {
        linkName: "LinkedIn",
        iconName: "linkedin",
        faStyle: "fa-brands",
      },
      {
        linkName: "Pinterest",
        iconName: "pinterest",
        faStyle: "fa-brands",
      },
      {
        linkName: "Vimeo",
        iconName: "vimeo",
        faStyle: "fa-brands",
      },
      {
        linkName: "Youtube",
        iconName: "youtube",
        faStyle: "fa-brands",
      },
      {
        linkName: "Social Media/Text",
        iconName: "comments",
        faStyle: "fas",
      },
      {
        linkName: "Video/Graphic",
        iconName: "photo-video",
        faStyle: "fas",
      },
      {
        linkName: "PDF Document",
        iconName: "file-pdf",
        faStyle: "fas",
      },
      {
        linkName: "Phone",
        iconName: "mobile",
        faStyle: "fas",
      },
    ];

    const getIconsForLinkTypes = (needleLink) => {
      let classString = "";

      const thisLink = faIconsForLinkTypes.find((link) => {
        return link.linkName === needleLink.linkType;
      });

      if (thisLink && thisLink.faStyle) {
        classString += thisLink.faStyle;
      } else {
        classString += `fa-solid`;
      }
      return thisLink
        ? `${classString} fa-${thisLink.iconName}`
        : `${classString} fa-browser`;
    };

    return {
      globeOutline,
      logoFacebook,
      mailOutline,
      getIconsForLinkTypes,
      openOutline,
    };
  },
};
</script>
<style scoped>
.profile-header-info {
  text-align: center;
  font-size: 1.5rem;
  margin: 15px 0 0;
}

.profile-links {
  /* background: #f4f4f4; */
}
ion-avatar {
  width: 100px;
  height: 100px;
}
.img-rounded {
  margin: 0 auto;
  width: 100px;
}
.img-rounded img {
  border-radius: 50%;
}
.content {
  min-height: auto;
  padding-bottom: 50px;
  border-radius: 6px;
}
.icon {
  font-size: 10px;
}
.contact-item a {
  font-size: 16px;
}
.text-center {
  text-align: center;
  flex: 1;
}
</style>
